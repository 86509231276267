/**************
 10 TAB CSS START
***************/
/* tab color */
$tab-colors:(
    "primary":    $theme-primary,
    "secondary":  $theme-secondary,
    "success":    $theme-success,
    "info":       $theme-info,
    "warning":    $theme-warning,
    "danger":     $theme-danger,
    "light":      $theme-light,
    "dark":       $theme-dark,
);

@each  $tab-colorname , $tab-color  in $tab-colors {   
    .cdx-tab{        
        &.tab-#{$tab-colorname}{       
            li{
                a{
                    &.active{
                        background-color: rgba($tab-color,1) !important;        
                        color: $white;                 
                    }
                }
            }
        }   
    }
}

@each  $tab-colorname , $tab-color  in $tab-colors {   
    .cdx-tab{        
        &.tab-#{$tab-colorname}-border{       
            li{
                a{
                    border: 1px solid rgba($tab-color,1) !important;
                    color: rgba($tab-color,1) ;
                    &.active{
                        background-color: rgba($tab-color,1) !important;
                        color: $white;                 
                    }
                }
                +li{
                    margin-inline-start: 10px;
                }
            }
        }   
    }
}

.cdx-tab{
    width:fit-content;    
    display: flex;
    align-items: center;
    border-bottom: none;
    li{
        a{
            font-weight:500;
            padding:10px 25px;
        }
    }
}

.tab-primary li a.active{
    background-color: $theme-primary !important;
}
.cdx-tab.tab-primary li a.active,
.cdx-tab.tab-primary-border li a.active{
    background-color: $theme-primary !important;
}


.cdx-tab.tab-primary-border li a{
    border-color:  $theme-primary !important;
}