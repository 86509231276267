/*******************
 25 EMAIL START
*********************/
.email-sidebar{
    min-width:320px;   
    .btn{
        padding: 12px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        svg{
            margin-inline-end:5px;
        }
    }
    ul.sidebarmenu-list{
        margin-block-start: 20px;
        a.menu-item{
            display: flex;
            align-items:center;        
            width: 100%;
            border-radius: 5px;
            padding: 13px 13px;
            border: 1px solid $theme-border;
            font-size: 16px;           
            color: $font-light;               
            .icons{
                svg{
                    width:20px;
                    height:auto;
                    margin-inline-end: 10px;                       
                }
            }            
            .badge{
                margin-inline-start: auto;
                font-size: 10px;
                padding: 5px 7px;                   
            }
        }
        li{
            &:nth-child(n+2){
                margin-block-start: 15px;
            }
        }
    }
}
.email-body{
    margin-inline-start:30px;
    width: calc(100% - 50px);
    .email-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $theme-border;
        padding-block-end: 20px;
        .input-group{
            width:250px;
            .form-control{
                padding: 10px 15px;
            }
        }           
    }
    .nav-tabs{
        margin-block-end: 20px;
        margin-block-start: 20px;
        padding-block-end: 20px;
        border-bottom: 1px solid $theme-border;
        li{
            a{
                border: 1px solid $theme-border;
                padding: 10px 20px;
                border-radius: 5px;
                display: block;
                color: $theme-primary;
                font-weight:500;
                &:hover{
                    color: $theme-primary;
                }
                &.active{
                    background-color: $theme-primary;
                    color: $white;
                    border-color: $theme-primary;
                }
            }
            svg{                   
                width: auto;
                height: 20px;
                margin-inline-end:10px;
            }
            &:nth-child(n+2){
                margin-inline-start:15px;
            }
        }
    }
    .mailreact-list{          
        display: flex;
        align-items: center;        
        > li{
            display: flex;
            align-items: center;     
            &:nth-child(n+2){
                margin-inline-start:5px;
            }
            .form-check-input {          
                width: 38px;
                height: 38px;      
                display: block;
                margin-inline-end: 10px;
                .custom-input-label{
                    width: 32px;
                    height: 32px;
                    &:after{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            >a{                   
                padding: 10px 15px;
                line-height: 1;
                svg{
                    width: auto;
                    height: 18px;                       
                }
            }

             
            .theme-dropdown{
                li{
                    a{
                        padding: 0;
                    }
                }
            }
            .input-group{                
                .input-group-text{
                    svg{
                        width: auto;
                        height: 18px;
                    }
                }
            }
            .action-toggle{
                padding: 10px 15px;
                line-height: 1;
                >svg{
                    width: auto;
                    height: 18px;
                }
                &:hover{
                    color: $white;
                }
            }
        }
    }       
    .mailreact-right{
        display: flex;
        align-items: center;
    }
    .cdxapp-toggle{
        margin-inline-start: 15px;
        padding: 10px 15px;
        i{
            font-size: 18px;
        }
    }
    .mail-list {       
        max-height: 548px;
        min-height: 548px;
        overflow-y: auto;
        padding-inline-end: 10px;
        li{               
            padding: 15px 20px;
            border: 1px solid $theme-border;               
            border-radius: 5px;
            transition: all 0.5s ease;
            &:nth-child(n+2){
                margin-block-start:10px;
            }
            .mail-item{
                display: flex;
                align-items: center;
                font-size: 16px;
                svg{
                    width: 20px;
                    height: auto;
                }
                .custom-check-input{
                    margin-inline-end: 15px;
                }
                h6{
                    margin-inline-start: 15px;
                }
                p{
                    margin-inline-start:20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 65%;
                }
                .mail-right{
                    display: flex;
                    align-items: center;
                    width: fit-content;                       
                    color: $font-light;
                    margin-inline-start: auto;
                    span{
                        margin-inline-start: 5px;
                        font-size: 16px;
                    }
                    svg{
                        margin-inline-start:10px;
                    }
                }
            }           
            &:hover{
                background-color: $primary-08;
            }
        }
    }

    .seen-header{
        padding-block-end: 20px;
        border-bottom: 1px solid $theme-border;
        .userimg-wrap{
            margin-inline-end:20px;
        }
        .media{       
            align-items: center;
            h6{
                text-transform: capitalize;
            }
            p{
                color: $font-light;
                font-size: 14px;
                a{
                    color: $font-light;
                }
            }
        }
        .emailseen-setting{
            >li{
                display: inline-block;
                i{
                    font-size:20px;
                    color: $theme-primary;
                }
                &:nth-child(n+2){
                    margin-inline-start:15px;
                }
            }
            .action-dropdown{
                min-width: 185px;
            }
        }
    }

    .seen-body{
        padding-block-start: 20px;     
        height: 553px;
        overflow-y: auto;  
        h5{
            text-transform: capitalize;
            margin-block-end:15px;
        }
        p{
            color: $font-light;
            +p{
                margin-block-start: 15px;
            }
        }
        .email-post{
            margin-block-start: 30px;
            margin-block-end: 30px;
            margin-inline-start: -15px;
            li{
                display: inline-block;
                margin-inline-start: 15px;               
                width:10%;
                img{                    
                    border-radius:5px;  
                    width:100%;                 
                }                
            }
        }
        .email-reply{
            margin-block-start:20px;
        }

        
    }
    .seen-footer{
        padding-block-start: 20px;
        margin-block-start: 30px;
        border-top: 1px solid $theme-border;
        .btn{
            text-transform: capitalize;
            i{
                margin-inline-end:10px;
            }
            &:nth-child(n+2){
                margin-inline-start: 20px;
            }
        }
    }



}
.email-modal{
    #dmekikeditor{
        height:150px;
        &:focus{
            box-shadow: none;
            outline:none;
        }
    }
    .modal-footer{
        .group-btn{
            text-align:end;
            .btn{
                svg{
                    width: auto;
                    height: 18px;
                    margin-inline-end: 8px;
                }
                &:nth-child(n+2){
                    margin-inline-start: 15px;
                }
            }
        }
    }
}
.cdxemail-contain{
    display:flex;
    position:relative;
}