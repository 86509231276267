/***********************
  36 DASHBOARD START
*************************/
.common-dash{   
    tr{       
        th{
            font-size:16px;
            padding-block-start:unset;
            font-weight:500;
            color: $codex-font-color;
        }   
        th,td{
            white-space: nowrap;           
        }
        td{         
            font-size: 14px;       
            font-weight: 400;
            vertical-align: middle;
            border-bottom: 1px solid $theme-border;
            color: $font-light;           
            .badge{
                transition: all 0.4s ease;
               
            }
            .media{
                align-items:center;
                >img{
                    margin-inline-end: 15px;
                }
            }
            span{
                display: block;
                color: $font-light;
                text-transform: capitalize;
            }
            i{          
                font-size: 20px;
                font-weight: 500;
            }
        }    
        .media{
            .img-wrap{
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-inline-end: 15px;
                border-radius: 5px;
                overflow: hidden;
                transition: all 0.4s ease;
                background-color: $primary-01;
            }
        }
        &:hover{
            .media{
                .img-wrap{
                    box-shadow: inset 0 0 0 1.2em $primary-02;
                }
            }
            td{ 
                .badge{
                    transition: all 0.4s ease;
                }
                .badge_primary{
                    background-color: $theme-primary !important;                                      
                    color: $white !important;
                }
                .badge_secondary{                   
                    background-color: $theme-secondary !important;
                    color: $white !important;
                }
                .badge_danger{                   
                    background-color: $theme-danger !important;
                    color: $white !important;
                }
            }
        }
    }  
     .badge{
        i{
            font-size: 16px;
            margin-inline-start:5px;
        }
     }
    .copycode-grid{
        padding:0px;
        max-height: 0;        
        pre{
            margin: 0;
        }  
        &.active{
            max-height: 100%;            
            transition: all 0.5s ease-in;
        }
    }   
}

/* welcome-card */
.welcome-card {
    .card-header{
        background-image: url('../images/dashboard/welcome-banner.jpg');       
        background-size: cover;
        background-position: center;
        height: 208px;
    }
    .card-body{
        text-align: center;
        .img-wrap{
            border-radius: 50%;
            overflow: hidden;
            width: 80px;
            height: 80px;
            margin-inline-start: auto;
            margin-inline-end: auto;
            margin-block-start: -65px;
            margin-block-end: 15px;
            background-color: $primary-03;
            img{
                width: 100%;
                height: 100%;
            }
        }
        h3{                        
            margin-block-end: 10px;
        }       
        p{
            font-size: 15px;
        }
    }
}


/* earning-chart */
.earning-chart{
    position:relative;
    .card-body{        
        padding: 0 !important;    
        overflow: hidden ;   
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px; 
        .site-impres{
            padding-inline-start:25px;
            margin-block-start:10px;
            h2{               
                font-size: calc(28px + (32 - 28) * ((100vw - 420px) / (1920 - 420)));
            }
        }      
        #earning-chart{
            margin-block-end: -20px;
            margin-inline-start: -20px;
            margin-inline-end: 0;
        }        
    }    
    .vactor-animated{
        li{
            svg{
                fill: $primary-01;
            }
        }
    }
}

.animated-shap{
    overflow: hidden;   
    .card-header{ 
        z-index:1;
    } 
    .vactor-animated{       
        li{
            position: absolute;           
            svg{
                width:30px;
                height:30px;               
            }
            &:nth-child(1){
                top: -10px;
                left: 45%;
                svg{
                    width: 45px;
                    height: 45px;
                }
            }
            &:nth-child(2){
                top: 0;
                left: 38%;               
            }
            &:nth-child(3){
                top: 13%;
                left: 52%;
            }
            &:nth-child(4){
                top: 15%;
                left: 43%;
            }    
            &:nth-child(5){
                top: -12px;
                left: 56%;
            }        
        }
    }
}

/* product chart */
.product-chart{         
    .card-body{
        padding:0 !important;
        overflow:hidden;
        z-index:1;
        #product-chart{
            margin-inline-start: -15px;            
            margin-block-end: -30px;
        }
    }   

    .vactor-animated{
        li{
            svg{
                path{
                    stroke: rgba($theme-secondary,0.2);                    
                }
            }
        }
    }
}

/* countries  tbl */
.countri-tbl{
    tr{
        td{                    
            padding-block-start:10px;
            padding-block-end:10px;       
            .flag-wrap{
                i{
                    font-size:24px;
                }
            }
            i{
                font-size:20px;            
            }                       
            .media-body{
                margin-inline-start:15px;
            }
        }              
    }
}

/* visitor ratetbl*/
.visitor-ratetbl{
    .card-body{
        padding-block-start:0;       
    }
}

/* recent-ordertbl */
.recent-ordertbl{
    tr{
        th{
            padding-block-start: 0;
            font-size: 16px;
        }                 
    }
}
/* project-status */
.project-status{   
    .card-header{
        .setting-card{
            margin-inline-start: auto;
        }
    }
    .media{
        align-items: center;
        justify-content: space-between; 
        margin-block-end:17px;       
        margin-block-start:17px;          
        .media-body{           
            text-align: end;  
        }
    }       
    .progress{
        height:15px;
    } 
}


/* project-summarytbl */
.project-summarytbl{
    tr{
        th{
            padding-block-start: 0;
        }
        td,th{                                      
            .team{               
                li{
                    display: inline-block;
                    &:nth-child(2){
                        margin-inline-start: -15px;
                        margin-inline-end: 5px;
                    }
                }  
            }
        }               
    }        
}

/*dash live chat*/
.dash-chat{
    .dash-chatbox{
        ul.chating-list{
            background-color: $primary-005;
            padding: 15px;
            border-radius: 5px;
            height: 332px;
            overflow: auto;
            li{
                .user-msgbox,.admin-msgbox{
                    max-width: 70%;
                    width:fit-content;        
                    .media{
                        img{
                            width: 28px;
                            height: 28px;
                        }
                        p{
                            background-color: $white;                           
                            padding: 5px 10px;                           
                        }
                    }
                }
                .user-msgbox{
                    .media{
                        img{
                            margin-inline-end: 10px;
                        }
                    }
                }
                .admin-msgbox{
                    margin-inline-start: auto;
                    .media{
                        img{
                            margin-inline-start: 10px;
                        }
                    }
                }
                +li{
                    margin-block-start: 10px;
                }
            }
        }
        .userchat-typebox{
            padding-block-start:25px;
            align-items: center;
            .btn{
                padding: 10px 12px;
                svg{
                    width:20px;
                    height:auto;
                }
            }
        }
    }
}


/* visitor-performance */
.visitor-performance{
    .apexcharts-legend{        
        top: 0 !important;
        left: unset !important;
        right: 20px !important;
        width: fit-content;
        padding: 0 ;       
        span{           
            font-weight: 600 !important;
            color: $font-light !important;
        }
    }
    .card-body{
        padding:38px 10px 0 10px !important;
        overflow: hidden;
        #visitor-chart{
            margin-block-start: -15px;
        }
        h4{
            padding-inline-start: 25px;
            color: $theme-primary;
        }
    }
}

/* support-inboxtbl */
.support-inboxtbl{  
    h6{
      margin-block-end: 3px;
      color: $codex-font-color;
    }
    p{        
      font-weight: 400;    
    }
    tr{
      &:first-child{
        td{
          padding-block-start: unset;
        }
      }
      td{
        padding-block-start: 13px;
        padding-block-end: 13px;
      }
    }
  }