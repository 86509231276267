/*********************
 35 COMING SOOM START
**********************/
.coming-soon {
	background: url('../../assets/images/coming-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;	
	&:before {
		position: absolute;
		content: '';
		background: rgba($black,0.7);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.newsletter-form {
		position: relative;
		margin-block-start: 55px;
		width:450px;
		margin-inline-start: auto;
		margin-inline-end: auto;
		.input-group{
			overflow: hidden;
			border-radius:30px;
			.form-control {
				height: 50px;
				display: block;		
				border: none;
				border-radius: 50px;
				font-size: 14px;
				padding-block-start: 0;
				padding-inline-end: 0;
				padding-block-end: 0;
				padding-inline-start: 25px;
			}
			span{
				padding: 0;
				border: none;
				button {		
					border: none;
					height: 100%;				
					background: $theme-primary;
					display: inline-block;
					color: $white;
					padding-inline-start: 30px;
					padding-inline-end: 30px;		
					border-top-right-radius: 30px;
    				border-bottom-right-radius: 30px;
					&:hover {
						background-color: $black;
						color: $white;
					}
				}
			}
		}
	}
	.social-list {
		margin-block-start: 35px;	
		a {
			color: $theme-primary;		
			width: 40px;
			height: 40px;					
			background: $white;
			border-radius: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition:all 0.5s ease;
			&:hover {
				background: $theme-primary;
				color: $white;
			}
		}
		li{
            display: inline-block;
			+li{
				margin-inline-start:10px;
			}
		}
	}
	.countdown-wrap{
		margin-block-start: 30px;
	}
	.countdown-grid {
		background: $theme-primary;
		border-radius: 10px;
		padding: 20px;	
		position: relative;	
		z-index: 1;	
		h2 {
			color: $white;
		}
		h5 {
			color: $white;
		}
	}
	h1{	
		font-size: calc(38px + (46 - 38) * ((100vw - 320px) / (1920 - 320)));
		margin-block-end: 15px;
	}
	p{
		font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
		font-weight:300;		
		margin-inline-start: auto;
		margin-inline-end: auto;
		color: $white;
	}
}