/*********************
 30 CONTACT START
**********************/
.contact-card{     
  text-transform: capitalize;
  .media{
    background-color: $bg-light;
    position: relative;
    padding: 25px;
    border-radius: 5px;
    margin-block-end: 20px;
    .user-setting{     
      .action-dropdown{
        min-width: 170px;
      }
    }
  }
  .user-imgwrapper{
      width: 65px;
      height: 65px;       
      border-radius: 50%;                
      margin-inline-end: 15px;
      img{
          width: 100%;
          height: 100%;
          border-radius:5px;
      }
  }   
  p{
    font-size:14px;
  }
  .info-list{
    margin-block-start:15px;
    li{
      font-size: 14px;
      font-weight: 500;
      color: $font-light;
      a{
        color: $font-light;
      }
      span{
        min-width:90px;
        display: inline-block;
        color: $codex-font-color;
      }
      &:nth-child(n+2){
        margin-block-start:5px;
      }
    }
  } 
  .user-action{
    margin-block-start: 20px;
    padding-block-start: 20px;
    border-top: 1px solid $theme-border;   
    display: flex;
    .btn{
      width:100%;     
      font-size:20px;     
      &:nth-child(n+2){
        margin-inline-start:15px;
      }
    }
  }
}
.contact-searchbar{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  .input-group{
    width:300px;
    .input-group-text{
      background-color: $white;
    }
  }
}