/****************
  16 SIDEBAR START
*****************/
.codex-sidebar{
  position: fixed;
  top:0;
  inset-inline-start: 0;
  width: 270px;
  height: 100%; 
  transition:all 0.5s ease;
  z-index:4; 
  background-color: $white;
  box-shadow: 0 8px 24px rgba(168, 180, 208, 0.1);
  border-right: 1px solid $theme-border;
  .logo-gridwrap,.icon-logo{
      padding:24px 20px;     
      display: flex;
      justify-content: space-between;
      align-items: center;     
      inset-block-start: 0;
      inset-inline-start: 0;          
      transition: all 0.5s ease;
      // box-shadow: 0 0 20px 5px rgba($black,0.05);
      border-bottom: 1px solid $theme-border;
      .sidebar-action{              
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: none;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 5px 2px rgba($black,0.05);
        color: $theme-primary;
        position: relative;       
        svg{
          color: $theme-primary;
          width: 16px;
        }
    }
    .codex-darklogo{
      display: none;
    } 
  } 
  .icon-logo{
    display:none;
  }
  
  .codex-menu{
    margin-block-start:20px;
    padding-block-end: 38px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    height: calc(100vh - 85px);
  } 
  &.sidebar-dark{
    background-color:$dark-card;
    .codexbrand-logo{
      display: none;
    }
    .codex-darklogo{
      display: block;
    }
    .logo-gridwrap{
      border-color: $dark-border;
    }
    .codex-menu {
      li {
        a{
          color: $dark-font;         
        }
      }
      .submenu-list {
        a{
          color: $dark-small-font;
        }
      }
    }
  }
  &.sidebar-gradient{
    background-image: linear-gradient(45deg, $theme-primary 30%, $theme-secondary);
    .codexbrand-logo{
      img{
        filter: invert(1);
      }
    }
    .cdxmenu-title{
      h5{
        color: $white;
      }
    }
    .codex-menu {
      li {
        a{
          color: $white;
        }
      }
    }
  }

}

.codex-menu {   
  padding-block-start: 0;
  .cdxmenu-title {
      padding: 5px 0;
      font-weight: 600;       
      margin-block-end:10px;
      margin-block-start: 10px;
      h5 {           
          font-size: 14px;
          text-transform: capitalize;           
          font-weight: 600;
          color: $font-light;
      }
  }

  li {
      display: block;
      position: relative;
      transition: all 0.5s ease;
      &.menu-item{
          &.active{
              >a{
                  background-color:$theme-primary;
                  color: $white;
              }
          }
          &:hover{
            >a{
                background-color:$theme-primary;
                color: $white;
            }
          }
      }
      >a {
          padding: 10px 10px;
          .icon-item {
              margin-inline-end: 10px;
              line-height: 1;
              i {
                  font-size: 18px;
              }
              svg {
                  width: 18px;
                  height: auto;
              }
          }
          >svg {
              margin-inline-end: 10px;
          }
          >i {
              font-size: 18px;

              &.fa {
                  margin-inline-start: auto;
              }
          }

          span {
              // transition: all 0.5s ease;
              line-height: 1;
          }
      }
      a {
          font-style: normal;           
          font-size: 16px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          position: relative;
          color: $font-light;
          padding: 10px 10px 10px 7px;
          margin: 7px 0;
          border-radius: 5px;
      }
      .submenu-list,
      .secondsubmenu-list {
          display: none;
      }
      &.active {
          .submenu-list,
          .secondsubmenu-list {
              display: block;
          }
      }
  }
  .submenu-list {
      padding-inline-start: 10px;
      a {
          padding-inline-start: 35px;                      
          color: $font-light;
          padding:5px 25px;
      }
      >li{
          &.active{
              >a{
                  color: $theme-primary;
              }
          }
      }
  }



}

/****************
SIDEBAR END
*****************/