/*******************
  14 DROPDOWN START
********************/

button{
    &.icon-Btn{
        display: flex;
        align-items:center;
        justify-content: center;
        width:35px;
        height:35px;
        border-radius: 50% !important;
        i{
            font-size: 16px;
        }
    }
}

/* theme drop down */
.action-menu{
  position: relative;
  .action-toggle{
      display: inline-block;
      background:transparent;
      box-shadow:none;
      border:none;
      i{
        font-size:22px;
        color: $font-light;
      }
      &:hover{
        color: $theme-primary;
      }
  }
  .action-dropdown{
      position: absolute;
      inset-inline-end:0;       
      background-color: $white;           
      box-shadow: 0 0 5px 0px rgba($black,0.10);
      border-radius: 5px;             
      z-index: 1;        
      min-width: 120px;
      opacity: 0;
      visibility: hidden;
      transform: translateY(5px);
      transition: all 0.5s ease;
      &.active{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
      li{
          width: 100%;
          display: block;         
          text-transform: capitalize;
          a,button,.btn{
              color: $font-light;             
              display: flex;
              align-items: center;
              font-size: 14px;
              padding: 10px 18px;
              i{
                  font-size: 14px;
                  margin-inline-end: 10px;
              }
              svg{
                  width: auto;
                  height: 18px;
                  margin-inline-end: 10px;
              }
              &:focus,
              &:active{
                  box-shadow:none;
                  outline:none;
              }
          }          
          &:nth-child(n+2){             
              border-top: 1px solid $theme-border;
          }
      }
      &.open{
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
      }
  }
}

.highlight{
  background-color:#f5f2f0;
  border-radius: 3px;
  padding: 0px 3px;
  color: $theme-primary;
}
.highlight a{
  color: $theme-primary;
  text-decoration: underline;
}