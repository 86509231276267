/****************
  18 CUSTOMIZER START
*****************/
.customizer-layer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black,0.2);
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 5;

  &.active{
    opacity: 1;
    visibility: visible;
  }
}
.customizer-action{
  position: fixed;
  top: 42%;
  display: none;
  inset-inline-end: 0;
  background-color:$white;
  padding: 12px;   
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 5;
  box-shadow: 0 0 20px 5px rgba($black,0.05);
  transition: all 0.3s ease-in;
  svg{
    width:18px;
    height:auto;
    color:$theme-primary;
    transition: all 0.3s ease-in; 
    animation:settingrotate 2.2s linear infinite;        
  }
  &:hover{
    background-color: $theme-primary;
    svg{
      color: $white;
    }
  }
}
.theme-cutomizer{  
  position: fixed;
  top: 0px;
  inset-inline-end: -280px;
  width: 280px; 
  height: 100vh;
  background-color: $white;
  z-index: 9;
  box-shadow: 0 0 40px 5px rgba($black,0.10);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.5s ease;
  overflow: auto;
  &.active{
    inset-inline-end: 0;    
  }
  .customizer-header{
    padding:20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $theme-border;
    .close-customizer{
      width:30px;
      height:30px;
      border-radius:50%;
      background-color: $primary-01;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in;
      svg{
        width:18px;
        color: $theme-primary;
        transition: all 0.3s ease-in;
      }
      &:hover{
        background-color: $theme-primary;
        svg{
          color:$white;
        }
      }
    }
  } 
  .customizer-body{
    padding: 20px;
    .cutomize-group{
        margin-block-end:20px;       
    }
    .customizer-title{     
      font-size: 14px;
      color: $black;
      text-transform: uppercase;
      margin-block-end: 10px;
    }
    .customizeoption-list{
      margin-inline-start: -15px;
      margin-bottom: -10px;
      li{
        border: 1px solid $theme-border;
        padding: 10px 30px;
        border-radius: 5px;
        text-transform: capitalize;
        display: inline-block;
        cursor: pointer;
        color: $font-light;
        transition: all 0.5s ease;
        margin-inline-start: 15px;    
        margin-bottom: 10px;   
        &.active-mode{
          color: $white;
          background-color: $theme-primary;
          border-color: $theme-primary;
        }
        &.color1{
          background-color: $theme-primary;
        }
        &.color2{
          background-color: #6610f2;
        }
        &.color3{
          background-color: #01A3FF;
        }
        &.color4{         
          background-color: #dc3545;
        }
        &.color5{
          background-color: #20c997;         
        }
        &.color6{
          background-color: #432d29;
        }       
        &.color7{
          background-color: #027864;
        }       
        &.color8{
          background-color: #1D3E56;
        }       
        &.color9{
          background-color: #008573;
        }       
      }
    }
    .themecolor-list{
      padding-left: 15px;
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      margin-bottom: 0px !important; 
      li{       
        width: 30px;
        height: 30px;
        background-color: $theme-primary;     
        margin-inline-start:0px;
        margin: 0 !important;
        padding: 0;
      }
    }
  }  
}



@keyframes settingrotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
    // transform: rotate(1turn);
  }
}

